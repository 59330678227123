<template>
	<section class="section-wrapper">
			<base-category :categoryData="dummyData"/>
			<grid-template cols="4">
				<product-card v-for="card in value.product_card" :key="card.product_title" :card="card"/>
			</grid-template>
	</section>
</template>

<script>
import BaseCategory from '../../components/molecules/BaseCategory/BaseCategory.vue'
import ProductCard from '../../components/molecules/cards/ProductCard/ProductCard.vue'
import GridTemplate from '../../templates/GridTemplate.vue'
export default {
	components: {
		BaseCategory,
		GridTemplate,
		ProductCard
	},
	props: {
		prefix: String,
		value: Object
	},
	data () {
		return {
			dummyData: {
				category: [
					{
						category_items: [
							{ category_item: 'energooszczędność' },
							{ category_item: 'bezpieczeństwo i zdrowie' },
							{ category_item: 'design' }
						],
						category_name: 'Cechy produktu'
					},
					{
						category_items: [
							{ category_item: 'standard' },
							{ category_item: 'premium' },
							{ category_item: 'exclusive' }
						],
						category_name: 'Kategorie'
					}
				]
			}
		}
	}
}
</script>
